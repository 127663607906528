import React from 'react'
import Slider from 'react-slick'
import {
    Container,
    Header,
    Segment,
    Divider,
    List,
    Image,
    Grid,
    Icon
} from "semantic-ui-react"
import phone_img from '../images/phone.png'
import bus_img from '../images/transportation.png'
import map_as from '../images/map_as.png'
import map_ntu from '../images/map_ntu.png'

const Contact = () => {
    return (
        <div>
            <Slider className="slide">
                <Segment inverted vertical id="contact_segment"/>
            </Slider>
            <Container>
                <Divider horizontal>
                    <Header as='h1'>
                        Contact Us
                    </Header>
                </Divider>
                <Grid stackable>
                    <Grid.Column width={11}>
                        <Header as='h2'>
                            For general enquiries of TIGP and application, please contact:
                        </Header>
                        <p>
                            &emsp;TIGP Administrative Office<br/>
                            &emsp;Taiwan International Graduate Program<br/>
                            &emsp;128 Academia Rd., Section 2<br/>
                            &emsp;Nankang, Taipei 115<br/>
                            &emsp;Taiwan<br/>
                            &emsp;E-mail: <a href="mailto:tigp@gate.sinica.edu.tw">tigp@gate.sinica.edu.tw</a><br/>
                            &emsp;Tel: +886-2-2789-8050<br/>
                            &emsp;Fax: +886-2-2785-8944<br/>
                            &emsp;Website: <a href="http://tigp.sinica.edu.tw">http://tigp.sinica.edu.tw</a>
                        </p>
                        <Header as="h2">
                        For specific information concerning the AIoT program, please contact:
                        </Header>
                        <p>
                            &emsp;Coordinator: Dr. Chuan-Ju Wang (Sinica), Prof. Hung-Yun Hsieh (NTU) <br/>
                            &emsp;Program Assistant: Mr. Jim Kao<br/>
                            &emsp;Research Center for Information Technology Innovation, Academia Sinica<br/>
                            &emsp;128 Academia Rd., Section 2<br/>
                            &emsp;Nankang, Taipei 115<br/>
                            &emsp;Taiwan<br/>
                            &emsp;Tel: +886-2-2787-2300 ext. 2300 <br/>
                            &emsp;Fax: +886-2-2785-2701<br/>
                            &emsp;E-mail: <a href="mailto:tigpaiot@gate.sinica.edu.tw">tigpaiot@gate.sinica.edu.tw</a><br/>
                            &emsp;Website: <a href="https://tigpaiot.iis.sinica.edu.tw">https://tigpaiot.iis.sinica.edu.tw</a>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={5} verticalAlign="bottom">
                        <Image src={phone_img} size="large"/>
                    </Grid.Column>
                </Grid>

                <Divider horizontal>
                    <Header as='h1'>
                        Transportation Information
                    </Header>
                </Divider>
                <Grid stackable>
                    <Grid.Column width={11}>
                        <Header as='h2'>
                            Bus
                        </Header>
                        <p>Route：205、212、212 (Express)、212 (Shuttle)、270、276、306、620、645、S12、BL25、679、S5、S1、CB7 (get off at Academia Sinica Stop)</p>
                        <Header as='h2'>
                            Train
                        </Header>
                        <p>Go to Nangang Station and transfer Bus 306, 205, 276, S12、212、679、S5 and get get off at Academia Sinica Stop.</p>
                    </Grid.Column>
                    <Grid.Column width={5} verticalAlign="bottom">
                        <Image src={bus_img} size="large"/>
                    </Grid.Column>
                </Grid>
                
                <Header as='h2'>
                    MRT
                </Header>
                <List bulleted size='huge'>
                    <List.Item>
                        <List.Content>
                        Go to Nangang Station and get out from Exit No.2, turn right and transfer buses 212 (Express/Shuttle), 270 or BL25 then get off at Academia Sinica Stop.
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                        Go to Taipei Nangang Exhibition Center Station and get out from Exit No.5, cross the street for bus 212, 276, 306, 620, 645, 679, 205, S5, S1、S12 then get off at Academia Sinica Stop.
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                        <u>Bannan (Blue) Line</u>：Go to Taipei Main Station and transfer to Nangang Station or Taipei Nangang Exhibition Center Station
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                        <u>Tamsui-Xinyi (Red) Line</u>：Go to Taipei Main Station and transfer Bannan (Blue) Line to Nangang Station or Taipei Nangang Exhibition Center Station
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                        <u>Wenhu (Brown) Line</u>：Go to Zongxiao Fuxing Station and transfer Bannan (Blue) Line to Nangang Station or Taipei Nangang Exhibition Center Station
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                        <u>Zonghe-Xinlu (Yellow) Line</u>: Go to Zongxiao Xinsheng and transfer Bannan (Blue) Line to Nangang Station or Taipei Nangang Exhibition Center Station
                        </List.Content>
                    </List.Item>
                </List>
                <Grid container stackable textAlign="center" columns={2}>
                    <Grid.Column width={8}>
                        <Image src={map_as} size="medium" centered/>
                        <Header as="h2"><a href="https://goo.gl/maps/bLtRvSUe32tGD4J29" target="_blank" rel="noreferrer"><Icon name="map signs"/>Academia Sinica</a></Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Image src={map_ntu} size="medium" centered/>
                        <Header as="h2"><a href="https://goo.gl/maps/agNDFFiy1BfycPsBA" target="_blank" rel="noreferrer"><Icon name="map signs"/>National Taiwan University</a></Header>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
    )
}

export default Contact