import React from 'react'
import {HashRouter, Route, Switch, Link} from 'react-router-dom'
import "semantic-ui-css/semantic.min.css"
import {
  Container,
  Menu,
  Segment,
  Grid,
  Image
} from "semantic-ui-react";
import "./App.css"

import About from './Components/About'
import Faculty from './Components/Faculty'
import Academic from './Components/Academic'
import Admission from './Components/Admission'
import Contact  from './Components/Contact'
import Student from './Components/Student'
import Form from './Components/Form'
import BackToTop from './images/back_to_top.png'

const totop = () =>{
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0; 
}

const App = () => {
  
  return (
    <div className="App">
        <HashRouter>
        <Container>
          <Menu stackable borderless inverted fixed="top" size="huge">
            <Link className="ui header item" to="/" onClick={totop}> TIGP AIoT Program</Link>
            <Link className="ui right item" to="/" onClick={totop}> About </Link>
            <Link className="ui item" to="/faculty" onClick={totop}> Faculty </Link>
            <Link className="ui item" to="/academic" onClick={totop}> Academic </Link>
            <Link className="ui item" to="/admission" onClick={totop}> Admission </Link>
            <Link className="ui item" to="/student" onClick={totop}>Student</Link>
            <Link className="ui item" to="/form_link" onClick={totop}>Form and Link</Link>
            <Link className="ui item" to="/contact" onClick={totop}> Contact </Link>
          </Menu>
        </Container>
        <div id="menu_space"/>
        <Switch>
                <Route exact={true} path="/" component={About}/>
                <Route path="/faculty" component={Faculty}/>
                <Route path="/academic" component={Academic}/>
                <Route path="/admission" component={Admission}/>
                <Route path="/student" component={Student}/>
                <Route path="/form_link" component={Form}/>
                <Route path="/contact" component={Contact}/>
        </Switch>
        &nbsp;&nbsp;&nbsp;
        <Segment vertical inverted style={{ padding: '1em 0em', backgroundColor: '#2f2f2f' }}>
          <Container>
            <Grid columns={2}>
              <Grid.Column>
                &copy; 2023 TIGP AIoT · All rights reserved<br/>
                Taiwan International Graduate Program 128 Academia Rd., Section 2 Nankang, Taipei 115<br/>
                Tel: +886-2-2787-2300#2363<br/>
                Fax: +886-2-2785-2701<br/>
                E-mail: <a href="mailto:tigpaiot@gate.sinica.edu.tw">tigpaiot@gate.sinica.edu.tw</a><br/>
                Website: <a href="https://tigpaiot.iis.sinica.edu.tw">https://tigpaiot.iis.sinica.edu.tw</a>
              </Grid.Column>
              <Grid.Column textAlign="right" as='a'>
                <div as='a' onClick={totop} id="text_back_to_top">Back to Top</div>
              </Grid.Column>
            </Grid>
          </Container>
        </Segment>
        
        <Image id="back_to_top" src={BackToTop} size="tiny" onClick={totop}/>
        </HashRouter>
    </div>
  );
}

export default App;
