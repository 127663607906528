import React from 'react'
import {
    Container,
    Header,
    Segment,
    Divider,
    Card,
} from "semantic-ui-react"
import Slider from 'react-slick'

import ChienYaoWang from '../images/Headshot/王建堯.jpg'
import LunWeiKu from '../images/Headshot/古倫維.jpg'
import LingJyhChen from '../images/Headshot/陳伶志.png'
import HenHsenHuang from '../images/Headshot/黃瀚萱.jpg'
import DeNianYang from '../images/Headshot/楊得年.jpg'
import MengTsungTsai from '../images/Headshot/蔡孟宗.jpg'
import JenChenLin from '../images/Headshot/林仁俊.jpg'
import YuTsao from '../images/Headshot/曹昱.jpg'
import JunChengChen from '../images/Headshot/陳駿丞.jpg'
import HsiangYunCheng from '../images/Headshot/鄭湘筠.jpg'
import ChihYuWang from '../images/Headshot/王志宇.jpg'
import ChuanJuWang from '../images/Headshot/王釧茹.png'
import YenNunHuang from '../images/Headshot/黃彥男.jpg'
import PiChengHsiu from '../images/Headshot/修匹承.jpg'
import AiChunPang from '../images/Headshot/逄愛君.jpg'
import HsinMuTsai from '../images/Headshot/蔡欣穆.jpg'
import CheLin from '../images/Headshot/林澤.jpg'
import HungYiLee from '../images/Headshot/李宏毅.jpg'
import WanjiunLiao from '../images/Headshot/廖婉君.png'
import HungYunHsieh from '../images/Headshot/謝宏昀.jpg'
import HungYuWei from '../images/Headshot/魏宏宇.jpg'
import HsuanJungSu from '../images/Headshot/蘇炫榮.jpg'
import DingYongHong from '../images/Headshot/洪鼎詠.jpg'
import RonaldYChang from '../images/Headshot/張佑榕.jpg'
import YuTeWang from '../images/Headshot/王有德.jpg'
import PangFengLiu from '../images/Headshot/劉邦鋒.jpg'
import FangJingWu from '../images/Headshot/巫芳璟.jpg'
import ShihweiLiao from '../images/Headshot/廖世偉.jpg'

import FacultyCard from './FacultyCard'

const iis_faculty_list = [
    {
        childKey: 0,
        image: ChienYaoWang,
        header: 'Chien-Yao Wang 王建堯',
        description: '',
        meta: 'kinyiu@iis.sinica.edu.tw',
        extra: 'https://www.iis.sinica.edu.tw/pages/kinyiu/contact_en.html',
        education: 'Ph.D., National Central University, Taiwan',
    },
    {
        childKey: 1,
        image: LunWeiKu,
        header: 'Lun-Wei Ku 古倫維',
        description: 'Natural Language Processing, Computational Linguistics, Sentiment (Opinion and Emotion) Analysis, Information Extraction and Retrieval, Chinese Language Processing, Topic Detection and Tracking, Artificial Intelligence, Computer Assisted Language Learning (CALL)',
        meta: 'lwku@iis.sinica.edu.tw',
        extra: 'https://homepage.iis.sinica.edu.tw/pages/lwku/contact_en.html',
        education: 'Ph.D., National Taiwan University, Taiwan',
    },
    {
        childKey: 2,
        image: JenChenLin,
        header: 'Jen-Chen Lin 林仁俊',
        description: 'Multimedia Content Understanding, Artificial Intelligence, Machine Learning, Affective Computing',
        meta: 'jenchunlin@iis.sinica.edu.tw',
        extra: 'https://homepage.iis.sinica.edu.tw/pages/jenchunlin/index_en.html',
        education: 'Ph.D., National Cheng Kung University, Taiwan'
    },
    {
        childKey: 3,
        image: DingYongHong,
        header: 'Ding-Yong Hong 洪鼎詠',
        description: 'Optimizing Compiler, Dynamic Binary Translation, Virtualization, Parallel Architecture and Parallel Computing',
        meta: 'dyhong@iis.sinica.edu.tw',
        extra: 'https://www.iis.sinica.edu.tw/pages/dyhong/index_en.html',
        education: 'Ph.D., National Tsing Hua University, Taiwan'
    },
    {
        childKey: 4,
        image: LingJyhChen,
        header: 'Ling-Jyh Chen 陳伶志',
        description: 'Networked Sensing Systems, Internet of Things, Big Data Analytics',
        meta: 'cclljj@iis.sinica.edu.tw',
        extra: 'https://www.iis.sinica.edu.tw/pages/cclljj/contact_en.html',
        education: 'Ph.D., Computer Science, University of California at Los Angeles, United States'
    },
    {
        childKey: 5,
        image: HenHsenHuang,
        header: 'Hen-Hsen Huang 黃瀚萱',
        description: 'Natural Language Processing, Computational Linguistics, Information Retrieval',
        meta: 'hhhuang@iis.sinica.edu.tw',
        extra: 'https://homepage.iis.sinica.edu.tw/pages/hhhuang/index_en.html',
        education: 'Ph.D., National Taiwan University, Taiwan'
    },
    {
        childKey: 6,
        image: DeNianYang,
        header: 'De-Nian Yang 楊得年',
        description: 'Social and Multimedia Networks and Applications, Data Mining and Machine Learning Algorithms and Applications, Network Analysis and Distributed Optimization/Learning',
        meta: 'dnyang@iis.sinica.edu.tw',
        extra: 'https://homepage.iis.sinica.edu.tw/pages/dnyang/contact_en.html',
        education: 'Ph.D., National Taiwan University, Taiwan'
    },
    {
        childKey: 7,
        image: MengTsungTsai,
        header: 'Meng-Tsung Tsai 蔡孟宗',
        description: 'Graph Theory, Streaming Algorithms, Lower Bound Techniques',
        meta: 'mttsai@iis.sinica.edu.tw',
        extra: 'https://homepage.iis.sinica.edu.tw/pages/mttsai/contact_en.html',
        education: 'Ph.D., Rutgers University - New Brunswick, United States'
    },
]

const citi_faculty_list = [
    {
        childKey: 0,
        image: AiChunPang,
        header: 'Ai-Chun Pang 逄愛君',
        description: 'Fog/Edge Computing, Wireless Networking, Mobile Computing, Multimedia Communications, Performance Modeling',
        meta: 'acpang@csie.ntu.edu.tw',
        extra: 'https://www.csie.ntu.edu.tw/~acpang/',
        education: 'Ph.D., National Chiao Tung University, Taiwan',
    },
    {
        childKey: 0,
        image: YenNunHuang,
        header: 'Yen-Nun Huang 黃彥男',
        description: '',
        meta: 'yennunhuang@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/yennunhuang/index_en.html',
        education: 'Ph.D., University of Maryland, United States',
    },
    {
        childKey: 1,
        image: ChihYuWang,
        header: 'Chih-Yu Wang 王志宇',
        description: 'Wireless Communications, Social Network, Game Theory, Network Economics, Machine Learning in Networks',
        meta: 'cywang@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/cywang/index_en.html',
        education: 'Ph.D., National Taiwan University, Taiwan',
    },
    {
        childKey: 2,
        image: ChuanJuWang,
        header: 'Chuan-Ju Wang 王釧茹',
        description: 'Computational Finance: lattice model, derivative pricing, risk management; Data Analytics: text mining, recommender systems, machine learning',
        meta: 'cjwang@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/cjwang/index_en.html',
        education: 'Ph.D., Department of Computer Science and Information Engineering, National Taiwan University, Taiwan',
    },
    {
        childKey: 3,
        image: PiChengHsiu,
        header: 'Pi-Cheng Hsiu 修丕承',
        description: 'Embedded Systems, Mobile Systems, Real-Time Systems',
        meta: 'pchsiu@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/pchsiu/index_en.html',
        education: 'Ph.D., National Taiwan University, Taiwan',
    },
    {
        childKey: 4,
        image: YuTsao,
        header: 'Yu Tsao 曹昱',
        description: 'Assistive Oral Communication Technologies, Speech Enhancement, Voice Conversion, Biomedical Signal Processing, Speech Signal Processing',
        meta: 'yu.tsao@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/yu.tsao/index_en.html',
        education: 'Ph.D., Georgia Institute of Technology, United States'
    },
    {
        childKey: 5,
        image: JunChengChen,
        header: 'Jun-Cheng Chen 陳駿丞',
        description: 'Computer Vision, Machine Learning, Deep Learning, Artificial Intelligence, Biometrics Applications (such as face recognition/facial analytics, activity recognition/detection, etc)',
        meta: 'pullpull@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/pullpull/index_en.html',
        education: 'Ph.D., University of Maryland College Park, United States',
    },
    {
        childKey: 6,
        image: HsiangYunCheng,
        header: 'Hsiang-Yun Cheng 鄭湘筠',
        description: 'Computer Architecture, Memory System Design, Energy-Efficient Multi-Core Systems',
        meta: 'hycheng@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/hycheng/index_en.html',
        education: 'Ph.D., Pennsylvania State University, United States',
    },
    {
        childKey: 7,
        image: RonaldYChang,
        header: 'Ronald Y. Chang 張佑榕',
        description: 'Wireless communication, AIoT',
        meta: 'rchang@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/~rchang/',
        education: 'Ph.D., University of Southern California, United States',
    },
    {
        childKey: 8,
        image: YuTeWang,
        header: 'Yu-Te Wang 王有德',
        description: 'Brain-Computer Interface, Visual Evoked Potential, Human-Computer Interaction',
        meta: 'yutewang@citi.sinica.edu.tw',
        extra: 'https://www.citi.sinica.edu.tw/pages/yutewang/index_en.html',
        education: 'Ph.D., University of California San Diego, United States',
    },
]

const csie_faculty_list = [
    {
        childKey: 1,
        image: HsinMuTsai,
        header: 'Hsin-Mu (Michael) Tsai 蔡欣穆',
        description: 'Visible light communications and positioning, Vehicular networking and communications, Vehicle and transportation systems',
        meta: 'hsinmu@csie.ntu.edu.tw',
        extra: 'https://www.csie.ntu.edu.tw/~hsinmu/site/',
        education: 'Ph.D., Carnegie Mellon University, United States',
    },
    {
        childKey: 2,
        image: PangFengLiu,
        header: 'Pang-Feng Liu 劉邦峰',
        description: 'Deep Learning System Optimization, parallel and distributed processing, algorithm design and analysis.',
        meta: 'pangfeng.liu@gmail.com',
        extra: 'https://sites.google.com/view/pangfengliu',
        education: 'Ph.D., Yale University, United States',
    },
    {
        childKey: 3,
        image: FangJingWu,
        header: 'Fang-Jing Wu 巫芳璟',
        description: 'Mobile Computing, Pervasive Intelligence, Wireless Networks, Internet of Smart Things, Distributed Intelligence and Artificial Intelligence, Privacy-preserving Techniques, Edge Computing, Cyber-Physical Systems',
        meta: 'fangjing@csie.ntu.edu.tw',
        extra: 'http://www.csie.ntu.edu.tw/~fangjing/',
        education: 'Ph.D., National Chiao Tung University, Taiwan',
    },
    {
        childKey: 4,
        image: ShihweiLiao,
        header: 'Shih-wei Liao 廖世偉',
        description: 'Blockchain and Big data, Fintech (Internet Finance), Android virtual machines and compilers',
        meta: 'liao@csie.ntu.edu.tw',
        extra: 'http://www.csie.ntu.edu.tw/~liao/',
        education: 'Ph.D., Stanford University, United States',
    },
]

const ee_faculty_list = [
    {
        childKey: 0,
        image: CheLin,
        header: 'Che Lin 林澤',
        description: 'Deep Learning, Data Science, Signal Processing in Wireless Communications, Systems Biology, FinTech, and Optimization Theory',
        meta: 'chelin@ntu.edu.tw',
        extra: 'https://www.ee.ntu.edu.tw/profile1.php?teacher_id=25203&p=3',
        education: 'Ph.D., University of Illinois, Urbana-Champaign, United States',
    },
    {
        childKey: 1,
        image: HungYiLee,
        header: 'Hung-Yi Lee 李宏毅',
        description: 'Machine Learning, Deep Learning, Spoken Language Understanding, Speech Recognition',
        meta: 'hungyilee@ntu.edu.tw',
        extra: 'https://www.ee.ntu.edu.tw/profile1.php?teacher_id=901182&p=3',
        education: 'Ph.D., National Taiwan University, Taiwan',
    },
    {
        childKey: 2,
        image: WanjiunLiao,
        header: 'Wanjiun Liao 廖婉君',
        description: '5G/6G, AI for Networking, Edge Computing and Learning, Metaverse communications, Web 3.0 and Blockchain, AIoT',
        meta: 'wjliao@ntu.edu.tw',
        extra: 'https://www.ee.ntu.edu.tw/profile1.php?id=66',
        education: 'Ph.D., Electrical Engineering, University of Southern California at Los Angeles, United States',
    },
    {
        childKey: 3,
        image: HungYunHsieh,
        header: 'Hung-Yun Hsieh 謝宏昀',
        description: 'IoT, next-generation communication systems, network science and cybersecurity',
        meta: 'hungyun@ntu.edu.tw',
        extra: 'https://www.ee.ntu.edu.tw/profile1.php?teacher_id=942014&p=3',
        education: 'Ph.D., Georgia Institute of Technology, United States',
    },
    {
        childKey: 4,
        image: HungYuWei,
        header: 'Hung-Yu Wei 魏宏宇',
        description: 'mobile and wireless networking, 3GPP standards, game theory for communications and networking, cross-layer design and optimization for wireless multimedia, wireless sensor networks and IoT, edge/fog computing, machine learning, 5G security ',
        meta: 'hywei@ntu.edu.tw',
        extra: 'https://www.ee.ntu.edu.tw/profile1.php?teacher_id=901161&p=3',
        education: 'Ph.D., Columbia University, United States',
    },
    {
        childKey: 5,
        image: HsuanJungSu,
        header: 'Hsuan-Jung Su 蘇炫榮',
        description: 'Wireless Communications',
        meta: 'hjs@ntu.edu.tw',
        extra: 'https://www.ee.ntu.edu.tw/profile1.php?teacher_id=942007&p=3',
        education: 'Ph.D., University of Maryland at College Park, United States',
    },
]

const FacultyCardGroup = ({faculty_list}) => {
    return (
        <Card.Group stackable itemsPerRow={4}>
                {faculty_list.map(function(faculty){
                    return <FacultyCard
                    image={faculty.image}
                    faculty_name={faculty.header}
                    education={faculty.education}
                    description={faculty.description}
                    email={faculty.meta}
                    website={faculty.extra}
                    />;
                })}
        </Card.Group>
    )
}

const Faculty = () => {
    return (
        <div>
            <Slider className="slide">
                <Segment inverted vertical id="faculty_segment"/>
            </Slider>
            <Container>
                <Divider horizontal>
                <Header as='h1'>Faculty List (Tentative)</Header>
                </Divider>
                <Header as='h2'>
                    Institute of Information Science, Academia Sinica				
                </Header>
                <FacultyCardGroup faculty_list={iis_faculty_list}/>
                <div><br/>• All faculty members in <a href="https://www.iis.sinica.edu.tw/en/page/People/ResearchFellows.html">Institute of Information Science</a>, Academia Sinica</div>
                <Header as='h2'>
                    Research Center for Information Technology Innovation, Academia Sinica				
                </Header>
                <FacultyCardGroup faculty_list={citi_faculty_list}/>
                <div><br/>• All faculty members in <a href="https://www.citi.sinica.edu.tw/en/people/research-fellows">Research Center for Information Technology Innovation</a>, Academia Sinica</div>
                <Header as='h2'>
                    Department of Computer Science and Information Engineering, National Taiwan University							
                </Header>
                <FacultyCardGroup faculty_list={csie_faculty_list}/>
                <Header as='h2'>
                    Department of Electrical Engineering, National Taiwan University							
                </Header>
                <FacultyCardGroup faculty_list={ee_faculty_list}/>

                <div id="menu_space"/>
                <div>• Note: all the above lists are ordered by the the number of strokes in Chinese surnames by default.</div>
            </Container>
        </div>
    )
}

export default Faculty